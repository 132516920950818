<template>
	<div class="corpus">
		<div class="defaultWrapper" style="z-index: 1">
			<div class="logo-holder">
				<img class="logo" :src="logo" />
			</div>
			<img style="position: absolute; left: -370px; bottom: -300px; z-index: -1" :src="rightTriangle" />
		</div>
		<el-card class="corpusList">
			<ul>
				<h1>Agent Knowledge Corpus</h1>
				<el-divider class="customDivider" />
				<div v-loading="loading" class="corpusDocContainer">
					<LazyList
						v-if="corpus.length"
						defaultLoadingColor="var(--el-color-primary)"
						:data="corpus"
						:itemsPerRender="20"
						containerClasses="corpusBody"
					>
						<template v-slot="{ item }">
							<li :key="item.id">
								<div @click="launchFile(item)" class="listContent">
									<img class="icon" :src="item.icon" />
									<span style="max-width: 300px; text-overflow: ellipsis; overflow: hidden">
										<el-tooltip
											class="box-item"
											effect="dark"
											:content="item.doc"
											placement="right"
										>
											{{ item.doc }}
										</el-tooltip>
									</span>
								</div>
							</li>
						</template>
					</LazyList>
				</div>
				<div class="blur"></div>
			</ul>
		</el-card>
	</div>
</template>

<script>
import LazyList from "lazy-load-list/vue";
import logo from "../assets/logo.svg";
import triangle from "../assets/triangle.svg";
import rightTriangle from "../assets/right-triangle.svg";
import doc from "../assets/fileTypes/doc.svg";
import csv from "../assets/fileTypes/csv.svg";
import pdf from "../assets/fileTypes/pdf.svg";
import txt from "../assets/fileTypes/txt.svg";
import url from "../assets/fileTypes/url.svg";
import json from "../assets/fileTypes/json.svg";
import audio from "../assets/fileTypes/audio.svg";
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
	components: {
		LazyList,
	},
	data() {
		return {
			iconMap: {
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document": doc,
				"text/csv": csv,
				"application/pdf": pdf,
				"text/plain": txt,
				url,
				"application/json": json,
				"audio/mpeg": audio,
			},
			logo: logo,
			triangle,
			rightTriangle,
			corpus: [],
			loading: false,
		};
	},
	async beforeMount() {
		await this.getCorpus();
	},
	methods: {
		launchFile(doc) {
			this.$emit("showFile", doc);
		},
		async getCorpus() {
			this.loading = true;
			const result = await axios.post(
				process.env.VUE_APP_API_ENDPOINT,
				{
					listKnowledgeBase: true,
					userInput: "undefined",
					sessionId: "undefined",
					resetSession: false,
				},
				{
					headers: {
						"x-api-key": process.env.VUE_APP_API_KEY,
					},
				}
			);
			if (!result.data?.knowledgeBase) {
				ElMessage.error("Oops, there was an issue loading the Agent Knowledge Corpus.");
				return;
			}
			this.corpus = [...result.data.knowledgeBase.documents, ...result.data.knowledgeBase.urls]
				.filter((each) => {
					return each.included;
				})
				.map((each) => {
					const mime = each.mineType || "url";
					return {
						doc: each.fileName || each.url,
						mimeType: mime,
						id: each.documentId,
						src: each.source || "",
						icon: this.iconMap[mime],
					};
				});
			setTimeout(() => {
				this.loading = false;
			}, 500);
		},
	},
};
</script>

<style scoped>
.logo-holder {
	position: relative;
	width: 156px;
	height: 127px;
	align-items: center !important;
	justify-content: center !important;
}

.logo-holder:before {
	-webkit-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
	content: "";
	width: 200px;
	height: 200px;
	z-index: -1;
	background: green;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(74deg);
	transform: translate(-50%, -50%) rotate(74deg);
	margin: -17px 0 0 -24px;
}

.logo {
	width: 122px;
	margin-left: 15px;
}

.corpusList {
	display: block;
	margin: auto;
	width: 70%;
	margin-top: 20px;
	z-index: 100;
	position: relative;
}

.icon {
	height: 30px;
	margin-right: 20px;
}

li {
	list-style-type: none;
	display: inline-flex;
	align-items: center;
	margin-bottom: 10px;
}

.listContent {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

.corpusDocContainer {
	height: 500px;
}

.blur {
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 200px;
	background: linear-gradient(to top, white, transparent);
	pointer-events: none;
}

.corpus /deep/ .customDivider {
	margin-bottom: 0px !important;
}

.corpusBody {
	overflow-y: scroll;
	padding-top: 24px;
	position: relative;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
}
</style>
