<template>
	<div class="common-layout">
		<el-container style="height: 100%">
			<el-main style="margin: 0px; padding: 0px" v-if="!isMobile">
				<pdfViewer
					@newScreenShot="newScreenShot"
					@backToCorpus="backToCorpus"
					:doc="doc"
					v-if="fileVisible"
					ref="pdfViewer"
				/>
				<corpusViewer v-if="!fileVisible" @showFile="showDoc" ref="corpusViewer" />
			</el-main>
			<el-aside class="aside" :width="isMobile ? '100%' : '40%'"
				><chatConsole ref="console" @toPage="toPage"
			/></el-aside>
		</el-container>
	</div>
</template>

<script>
import chatConsole from "@/components/chatConsole.vue";
import pdfViewer from "@/components/pdfViewer.vue";
import corpusViewer from "@/components/corpusViewer.vue";
export default {
	components: {
		chatConsole,
		pdfViewer,
		corpusViewer,
	},
	data() {
		return {
			isMobile: false,
			fileVisible: false,
		};
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.handleResize);
	},
	mounted() {
		this.handleResize();
		window.addEventListener("resize", this.handleResize);
	},
	methods: {
		newScreenShot(newScreen) {
			this.$refs.console.newScreenShot(newScreen);
		},
		backToCorpus() {
			this.fileVisible = false;
		},
		showDoc(doc) {
			this.doc = doc;
			this.fileVisible = true;
		},
		toPage(payload) {
			this.doc = payload.doc;
			this.fileVisible = true;
			this.$refs.pdfViewer.toPage(payload);
		},
		handleResize() {
			if (document.documentElement.clientWidth < 1080) {
				this.isMobile = true;
			} else if (document.documentElement.clientWidth > 1080) {
				this.isMobile = false;
			}
		},
		surveyComplete() {
			if (this.$refs.table) {
				this.$refs.table.refreshTable();
			}
		},
	},
};
</script>

<style scoped>
.common-layout {
	height: 100%;
}

.aside {
	border-left: 1px solid #e0e1e3;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
