import { createApp } from "vue";
import ElementPlus from "element-plus";
import "./styles/index.scss";

import VueCookies from "vue-cookies";

import Markdown from "vue3-markdown-it";

import VueHtml2Canvas from "vue-html2canvas";

import "highlight.js/styles/github-dark.css";

import App from "./App.vue";

const app = createApp(App);

import en from "element-plus/dist/locale/en.mjs";

import router from "./router";
import store from "./store";

app.use(VueCookies, { expires: "7d" });
app.use(router);
app.use(store);
app.use(ElementPlus, { locale: en, size: "large" });
app.use(Markdown);
app.use(VueHtml2Canvas);

app.mount("#app");
