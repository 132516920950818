<template>
	<div class="chatConsole">
		<div class="header">
			<img class="widgetIcon" :src="logo" />
			<el-select
				@change="agentChange"
				style="margin-left: 20px"
				size="small"
				v-model="currentAgent"
				placeholder="Select agent"
			>
				<el-option v-for="item in agents" :key="item.url" :label="item.description" :value="item.url">
				</el-option>
			</el-select>
			<el-button
				:loading="loading"
				@click="resetConversation()"
				style="margin-left: auto; margin-right: 20px"
				size="small"
				type="primary"
				plain
				bg
			>
				Reset the conversation
			</el-button>
		</div>
		<div
			style="
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				font-size: 10px;
				border-bottom: 1px solid #e0e1e3;
			"
		>
			Powered By
			<a style="color: #5f63df" href="https://tovie.ai/generative-ai-for-enterprise"
				><img style="height: 10px; margin-left: 5px" :src="tovieLogo"
			/></a>
		</div>
		<el-alert
			title="Important"
			type="warning"
			description="Please do not enter any sensitive personal and or company information into the chat."
			show-icon
		/>
		<LazyList
			v-if="chatHistory.length"
			defaultLoadingColor="var(--el-color-primary)"
			:data="chatHistory"
			:itemsPerRender="10000"
			v-loading="loadingTranscript"
			ref="consoleBody"
			containerClasses="consoleBody"
		>
			<template v-slot="{ item }">
				<div :key="item.chatId" :class="(item.actor === 'user' ? 'user' : 'agent') + ' chatItem fade-in'">
					<div class="avatarWrapper">
						<el-avatar
							:style="{ backgroundColor: item.actor === 'user' ? '#FA5687' : 'var(--el-color-primary)' }"
						>
							<b v-if="item.actor === 'user'" style="font-size: 16px">U</b>
							<img
								style="margin: 0px"
								v-if="item.actor !== 'user'"
								class="widgetIcon"
								:src="widgetIcon"
							/>
						</el-avatar>
					</div>
					<div
						:style="{
							width: !item.text ? '100%' : '',
						}"
						class="content"
					>
						<p>
							<thinkingAnimation v-if="!item.text" />
							<span v-if="item.images" class="imgPrevWrapper">
								<el-image
									:key="image.url ? image.url : image"
									v-for="image in item.images"
									class="imagePrev"
									:src="image.url ? image.url : image"
									fit="cover"
								>
									<template #error>
										<div class="image-slot">
											<el-icon><Picture /></el-icon>
											<span>Unable To Preview Image</span>
										</div>
									</template>
								</el-image>
							</span>
							<Markdown
								:highlight="{ auto: true, hljs: require('highlight.js'), code: true }"
								:source="item.text"
							/>
						</p>
						<div class="sources" v-if="item.context && item.context.length">
							<span>Potential sources:</span>
							<div class="fade-in">
								<el-button
									class="source"
									@click="responseSourceClick(option, item)"
									:key="i"
									v-for="(option, i) in item.context"
									size="default"
									type="primary"
									plain
								>
									{{ option.metadata.sourceName }}
									{{
										option.metadata.mimeType === "application/pdf" && option.metadata.pageNumber
											? `- Page: ${option.metadata.pageNumber}`
											: ""
									}}
								</el-button>
							</div>
						</div>
						<div v-if="item.feedback" class="feedback">
							<el-divider class="customDivider" />
							<span>Was this response helpful?</span>
							<div class="feedbackOptionsWrapper">
								<div :key="i" v-for="(option, i) in item.feedback.options" class="fade-in">
									<el-button
										v-if="readOnly ? (option.actioned ? true : false) : true"
										:disabled="readOnly ? true : option.actioned"
										:loading="
											loadingFeedback ===
											item.feedback.inferenceResponseChatId + '_' + option.text
												? true
												: false
										"
										@click="sendFeedback(option, item.feedback)"
										class="feedbackOption"
										size="small"
										:type="option.text === 'Yes' ? 'success' : 'danger'"
										plain
									>
										{{ option.text }}
									</el-button>
								</div>
							</div>
						</div>
						<span>{{ timeDifference(item.time) }}</span>
					</div>
				</div>
			</template>
		</LazyList>
		<el-alert
			v-if="chatHistory.length > 100"
			:closable="false"
			title="Performance Warning"
			description="Your conversation exceeds 100 messages. Consider resetting your conversation."
			type="warning"
			class="performanceWarning"
			show-icon
		/>
		<div class="inputWrapper">
			<imageUpload ref="imageUpload" />
			<input
				:disabled="disableInput"
				ref="textInput"
				@keyup.enter="processInput()"
				v-model="inputValue"
				:placeholder="disableInput ? 'Responding to your last message' : 'Your message'"
				class="queryInput"
				type="text"
			/>
			<el-divider v-if="!disableInput" style="height: 30px" direction="vertical" />
			<el-icon v-if="!disableInput" @click="processInput()" class="send"><Promotion /></el-icon>
		</div>
	</div>
</template>

<script>
import logo from "../assets/logo.svg";
import tovieLogo from "../assets/tovielogo.svg";
import axios from "axios";
import Markdown from "vue3-markdown-it";
import { Promotion, Picture } from "@element-plus/icons-vue";
import { v4 } from "uuid";
import thinkingAnimation from "./thinkingAnimation.vue";
import { timeDifference } from "../utils/time.js";
import widgetWhite from "../assets/widgetWhite.svg";
import LazyList from "lazy-load-list/vue";
import imageUpload from "./imageUpload.vue";
export default {
	components: {
		Picture,
		imageUpload,
		Promotion,
		Markdown,
		thinkingAnimation,
		LazyList,
	},
	async beforeMount() {
		this.loadingTranscript = false;
	},
	data() {
		return {
			currentAgent: window.location.href,
			agents: [
				{ url: "https://pilot.proman.general.tovie.ai", description: "General Agent" },
				{ url: "https://pilot.proman.mpa.tovie.ai", description: "MPA Agent" },
				{ url: "https://pilot.proman.sustainability.tovie.ai", description: "Sustainability Agent" },
				{ url: "https://pilot.proman.corporate.tovie.ai", description: "Corporate Agent" },
				{ url: "https://pilot.proman.finance.tovie.ai", description: "Finance Agent" },
			],
			logo: logo,
			tovieLogo: tovieLogo,
			widgetIcon: widgetWhite,
			loadingTranscript: true,
			loadingFeedback: "",
			inputValue: "",
			chatHistory: [],
			inferenceInProgress: false,
			loading: false,
			agentId: null,
			readOnlySession: null,
			showResetSessionWarning: false,
			readOnly: false,
			sessionId: null,
			disableInput: false,
			mockInferenceInProgress: true,
			fileName: null,
			recursionLimit: 2,
			current: 0,
		};
	},
	async mounted() {
		this.fileName = "EnergyFinanceReport.pdf";
		this.sessionId = v4();
		this.start(
			"Welcome. I am an AI agent scoped on the adjacent knowledge corpus. My job is to help answer questions related to the information contained in the corpus. So, how can I help you?"
		);
	},
	methods: {
		async sendFeedback(option, feedback) {
			feedback.options.forEach((option) => {
				option.actioned = false;
			});
			this.loadingFeedback = feedback.inferenceResponseChatId + "_" + option.text;
			await axios.post(
				`${process.env.VUE_APP_API_ENDPOINT.split("/channel/")[0]}/analytics/reportFeedbackOnInferenceResult/${
					feedback.inferenceResponseChatId
				}?choice=${option.text}`
			);
			setTimeout(() => {
				this.loadingFeedback = "";
				option.actioned = true;
			}, 500);
		},
		newScreenShot(newScreen) {
			this.$refs.imageUpload.newScreenShot(newScreen);
		},
		agentChange() {
			window.location.href = this.currentAgent;
		},
		timeDifference(date) {
			return timeDifference(new Date(), new Date(date));
		},
		getImagesIfAvailable() {
			const images = this.$refs.imageUpload.returnImages();
			return images?.length ? images : [];
		},
		processInput(specific) {
			if (!this.inputValue && !specific) {
				return;
			}
			const images = this.getImagesIfAvailable();
			this.inferenceInProgress = true;
			const value = this.inputValue || specific;
			this.inputValue = "";
			this.chatHistory.push({
				text: value,
				actor: "user",
				time: new Date(),
				images: images.length
					? images?.map((each) => {
							return each.url;
					  })
					: null,
			});
			this.$nextTick(() => {
				document.getElementsByClassName("consoleBody")[0].scrollTop =
					document.getElementsByClassName("consoleBody")[0].scrollHeight;
			});
			setTimeout(async () => {
				await this.processResponse(value, images);
			}, 500);
		},
		async processResponse(userInput, images) {
			const chatId = v4();
			this.chatHistory.push({
				text: "",
				actor: "agent",
				chatId: chatId,
				time: new Date(),
				feedback: null,
				context: [],
			});
			this.$nextTick(() => {
				document.getElementsByClassName("consoleBody")[0].scrollTop =
					document.getElementsByClassName("consoleBody")[0].scrollHeight;
			});
			const formData = new FormData();
			images = images?.map((image) => {
				return { name: image.raw.name, file: image.raw };
			});
			images?.forEach((image) => {
				formData.append("files", image.file, image.name);
			});
			formData.append("userInput", userInput);
			formData.append("sessionId", this.sessionId);
			formData.append("resetSession", false);
			formData.append("listKnowledgeBase", false);

			setTimeout(async () => {
				this.disableInput = true;
				const result = await axios.post(process.env.VUE_APP_API_ENDPOINT, formData, {
					headers: {
						"x-api-key": process.env.VUE_APP_API_KEY,
						"Content-Type": images?.length ? "multipart/form-data" : "application/json",
					},
				});
				if (result.data?.responses) {
					let messageChunks = [];
					const chat = this.chatHistory.find((each) => {
						return each.chatId === chatId;
					});
					let allContext = [];
					let feedback = result.data.feedback || null;

					const allMessageChunksCombined = result.data?.responses
						.map((each) => {
							return each.text;
						})
						.join("\n\n");

					if (allMessageChunksCombined.includes("An error occurred")) {
						this.current = this.current + 1;
						if (this.current <= this.recursionLimit) {
							await this.resetConversation(true);
							this.chatHistory.push({
								text: "",
								actor: "agent",
								chatId: chatId,
								time: new Date(),
								feedback: null,
								context: [],
							});
							const chat = this.chatHistory.find((each) => {
								return each.chatId === chatId;
							});
							chat.text =
								"Sorry, I have reached my context memory limit. I've had to forget about our previous conversation, but will do my best to deal with your original query.";
							await this.processResponse(userInput);
							return;
						} else {
							this.current = 0;
							await this.resetConversation(true);
							this.chatHistory.push({
								text: "",
								actor: "agent",
								chatId: chatId,
								time: new Date(),
								feedback: null,
								context: [],
							});
							const chat = this.chatHistory.find((each) => {
								return each.chatId === chatId;
							});
							chat.text =
								"Sorry, I have have been unable to deal with your original query. It may be that it is to large. Please try rephrasing.";
							this.disableInput = false;
							this.$nextTick(() => {
								this.$refs.textInput.focus();
							});
							return;
						}
					}

					messageChunks = result.data?.responses
						.map((each) => {
							allContext = [...allContext, ...each.context];
							return each.text;
						})
						.join("\n\n")
						.split("");

					this.mockInferenceInProgress = true;
					messageChunks.forEach((text, i) => {
						setTimeout(() => {
							chat.text = chat.text + text;
							if (i % 40 === 0) {
								this.$nextTick(() => {
									document.getElementsByClassName("consoleBody")[0].scrollTop =
										document.getElementsByClassName("consoleBody")[0].scrollHeight;
								});
							}
							if (i === messageChunks.length - 1) {
								this.mockInferenceInProgress = false;
								this.inferenceInProgress = false;
								this.disableInput = false;
								this.$nextTick(() => {
									this.$refs.textInput.focus();
								});
								chat.context = allContext;
								chat.feedback = feedback;
								//account for animation delay auto scroll down again
								this.$nextTick(() => {
									document.getElementsByClassName("consoleBody")[0].scrollTop =
										document.getElementsByClassName("consoleBody")[0].scrollHeight;
								});
							}
						}, i * 0);
					});
				}
			}, 0);
		},
		async resetConversation(doNotNotify) {
			this.mockInferenceInProgress = true;
			this.chatHistory = [];
			setTimeout(async () => {
				await axios.post(
					process.env.VUE_APP_API_ENDPOINT,
					{
						userInput: "nothing",
						sessionId: this.sessionId,
						resetSession: true,
						listKnowledgeBase: false,
					},
					{
						headers: {
							"x-api-key": process.env.VUE_APP_API_KEY,
						},
					}
				);
				this.mockInferenceInProgress = false;
			}, 500);
			if (!doNotNotify) {
				this.start("Conversation reset");
			}
		},
		async responseSourceClick(option) {
			if (option.metadata.mimeType === "application/pdf" && option.metadata.pageNumber) {
				let page = option.metadata.pageNumber;
				try {
					page = parseInt(page);
					this.$emit("toPage", {
						pageNumber: page,
						doc: { src: option.metadata.source, mimeType: "application/pdf" },
					});
				} catch (e) {
					if (!option.metadata.pageNumber) {
						window.open(option.metadata.source, "_blank");
					}
				}
			} else {
				if (option.metadata.source) {
					window.open(option.metadata.source, "_blank");
				}
			}
		},
		start(input) {
			this.chatHistory.push({
				text: input,
				actor: "agent",
				chatId: v4(),
				time: new Date(),
				feedback: null,
				context: [],
			});
			this.$refs.textInput.focus();
		},
	},
};
</script>

<style scoped>
.header {
	height: 50px;
	width: 100%;
	background-color: white;
	display: inline-flex;
	align-items: center;
	border-bottom: 1px solid rgb(224, 225, 227);
}

.chatConsole {
	height: 100%;
	background-color: white;
	position: relative;
	overflow: hidden;
}

.consoleBody {
	padding: 20px;
	overflow-y: scroll !important;
	height: calc(100% - 84px) !important;
	padding-bottom: 150px;
}

.chatItem {
	display: flex;
	flex-direction: row;
	align-items: top;
	margin-bottom: 10px;
}

.user {
	flex-direction: row-reverse;
}

.user .avatarWrapper {
	margin-left: 10px;
}

.chatItem p {
	margin-top: 0px;
	font-size: 16px;
}

.content {
	margin-left: 10px;
	background-color: #f4f8fe;
	padding: 10px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	max-width: 90%;
	word-wrap: break-word;
}

.content :deep(code) {
	border-radius: 4px !important;
	font-size: 12px;
}

.content span {
	font-size: 12px;
	color: #4b535b;
}

.user .content {
	margin-left: auto;
	background-color: #fef4f8;
}

.inputWrapper {
	height: 60px;
	width: 90%;
	background: white;
	margin: auto;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
	border-radius: 4px;
	border: 2px solid #e0e1e3;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 20px;
	margin: auto;
	left: 0;
	right: 0;
}

.performanceWarning {
	position: absolute;
	width: 90%;
	bottom: 100px;
	margin: auto;
	left: 0;
	right: 0;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
}

.resetSessionsWarning {
	position: absolute;
	width: 90%;
	top: 100px;
	margin: auto;
	left: 0;
	right: 0;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
	z-index: 10;
}

.queryInput {
	display: flex;
	flex-grow: 1;
	border: none;
	padding-left: 20px;
	font-size: 18px;
}

.send {
	font-size: 25px;
	margin-left: auto;
	margin-right: 20px;
	color: var(--el-color-primary);
	cursor: pointer;
	margin-left: 5px;
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.sources {
	margin-top: 10px;
}

.feedback {
	display: flex;
	flex-direction: column;
}

.sources .el-button + .el-button {
	margin-left: unset !important;
}

.source {
	margin-bottom: 10px;
	margin-right: 10px;
	margin-top: 10px;
}

.feedbackOption {
	margin-bottom: 10px;
	margin-top: 10px;
	margin-right: 10px;
}

.feedbackOptionsWrapper {
	display: flex;
}

.customDivider {
	margin: 10px 0;
}

.widgetIcon {
	height: 40px;
	margin-left: 20px;
}

.imagePrev {
	height: 200px;
	width: 200px;
	border-radius: 2px;
	margin-right: 20px;
}

.imagePrev .el-image {
	padding: 0 5px;
	max-width: 300px;
	max-height: 200px;
	width: 100%;
	height: 200px;
}

.imagePrev .image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: rgb(250, 86, 135);
	color: white;
	font-size: 30px;
	flex-direction: column;
	cursor: pointer;
}

.imagePrev .image-slot .el-icon {
	font-size: 40px;
}

.imagePrev .image-slot span {
	margin-top: 10px;
	color: white;
}

.imgPrevWrapper {
	display: flex;
	margin-bottom: 10px;
	margin-top: 10px;
}
</style>
