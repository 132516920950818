import { createRouter, createWebHistory } from "vue-router";

import main from "./pages/mainPage.vue";
import addDocument from "./pages/addDocumentPage.vue";

const routes = [
	{
		path: "/",
		component: main,
		name: "chat",
		meta: {
			pageTitle: "Proman Document Chat",
		},
	},
	{
		path: "/addDocument",
		component: addDocument,
		name: "addDocument",
		meta: {
			pageTitle: "Proman Document Create",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.pageTitle;
	next();
});

export default router;
