<template>
	<!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
	<!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
	<div style="position: relative; height: 100%; overflow: hidden">
		<div class="defaultWrapper" v-if="!fileIsPDF" style="z-index: 1">
			<div class="logo-holder">
				<img class="logo" :src="logo" />
			</div>
			<div style="width: 350px; margin: auto; position: absolute; z-index: 1; left: 0; right: 0; top: 35%">
				<el-alert
					title="Unable To Preview Document"
					type="warning"
					:closable="false"
					style="background-color: #ff8700; color: white"
					show-icon
				>
					<p>Document previews are only available for .PDF files</p>
				</el-alert>
				<el-button :icon="arrowLeft" plain style="margin-top: 20px" @click="backToCorpus" type="primary">
					Back to agent knowledge corpus
				</el-button>
			</div>
			<img style="position: absolute; left: -370px; bottom: -300px" :src="rightTriangle" />
		</div>
		<div v-if="fileIsPDF" class="wrapper">
			<el-button
				:icon="arrowLeft"
				plain
				style="position: absolute; z-index: 10000; left: 0; right: 0; top: -1px; border-radius: 0px"
				@click="backToCorpus"
				type="primary"
			>
				Back to agent knowledge corpus
			</el-button>
			<vue-pdf-app
				:key="doc.src"
				ref="pdfApp"
				@pages-rendered="pagesRendered"
				:config="{ toolbar: false }"
				:page-number="pageNumber"
				style="height: 100vh"
				:pdf="doc.src"
			></vue-pdf-app>
		</div>
		<screenShotTaker @newScreenShot="newScreenShot" v-if="fileIsPDF" />
	</div>
</template>

<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import logo from "../assets/logo.svg";
import triangle from "../assets/triangle.svg";
import rightTriangle from "../assets/right-triangle.svg";
import { ArrowLeft } from "@element-plus/icons-vue";
import { markRaw } from "vue";
import screenShotTaker from "./screenShotTaker.vue";
export default {
	props: {
		doc: Object,
	},
	data() {
		return {
			pageNumber: 1,
			loading: true,
			fileIsPDF: false,
			logo: logo,
			triangle,
			rightTriangle,
			arrowLeft: markRaw(ArrowLeft),
			pdfApp: null,
		};
	},
	components: {
		VuePdfApp,
		screenShotTaker,
	},
	mounted() {
		this.fileIsPDF = this.doc.mimeType === "application/pdf" ? true : false;
		setTimeout(() => {
			this.loading = false;
		}, 500);
	},
	methods: {
		newScreenShot(newScreen) {
			this.$emit("newScreenShot", newScreen);
		},
		backToCorpus() {
			this.$emit("backToCorpus");
		},
		toPage(payload) {
			this.pageNumber = payload.pageNumber;
			this.fileIsPDF = true;
			this.pdfApp.page = this.pageNumber;
			setTimeout(() => {
				this.loading = false;
			}, 500);
		},
		pagesRendered(pdfApp) {
			this.pdfApp = pdfApp;
		},
	},
};
</script>

<style scoped>
.wrapper {
	/* opacity: 0.1; */
	background-color: var(--el-color-primary);
	height: 100%;
	position: relative;
	padding-top: 39px;
}

.logo-holder {
	position: relative;
	width: 156px;
	height: 127px;
	align-items: center !important;
	justify-content: center !important;
}

.logo-holder:before {
	-webkit-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
	content: "";
	width: 200px;
	height: 200px;
	z-index: -1;
	background: green;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(74deg);
	transform: translate(-50%, -50%) rotate(74deg);
	margin: -17px 0 0 -24px;
}

.logo {
	width: 122px;
	margin-left: 15px;
}

.defaultWrapper /deep/ .el-alert__description {
	color: white;
}

.defaultWrapper /deep/ .el-alert {
	color: white;
	align-items: top;
}
</style>
