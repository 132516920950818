<template>
	<div class="imageUploadWrapper">
		<el-popover
			:offset="20"
			:visible="visibleNotice"
			placement="top"
			title="Image Captured"
			:width="260"
			content="Your captured image is available here."
		>
			<template #reference>
				<el-upload
					:limit="2"
					multiple
					accept="image/*"
					:disabled="fileList.length === 2"
					:on-change="handleChange"
					v-model:file-list="fileList"
					action="#"
					list-type="picture-card"
					:auto-upload="false"
				>
					<el-icon><Picture style="font-size: 20px" /></el-icon>
					<template #file="{ file }">
						<div>
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<el-icon><Delete style="font-size: 20px" /></el-icon>
								</span>
							</span>
						</div>
					</template>
				</el-upload>
			</template>
		</el-popover>
	</div>
</template>

<script>
import { Delete, Picture } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";

export default {
	components: {
		Picture,
		Delete,
	},
	data() {
		return {
			disabled: false,
			fileList: [],
			visibleNotice: false,
		};
	},
	methods: {
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(","),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		newScreenShot(newScreen) {
			if (this.fileList.length >= 2) {
				return;
			}
			const file = this.dataURLtoFile(newScreen.file, "canvasImage.png");
			this.fileList.push({
				name: file.name,
				percentage: 0,
				raw: file,
				size: file.size,
				status: "ready",
				uid: Date.now(),
				url: URL.createObjectURL(file),
			});
			this.visibleNotice = true;
			setTimeout(() => {
				this.visibleNotice = false;
			}, 2000);
		},
		handleRemove(file) {
			this.fileList = this.fileList.filter((each) => {
				return each.uid !== file.uid;
			});
		},
		handleChange(file) {
			if (file.size > 41943040) {
				//40mb size limit per image
				ElNotification({
					title: "Warning",
					message: "This image exceeds the 40mb size limit.",
					type: "warning",
					position: "bottom-left",
				});
				setTimeout(() => {
					this.handleRemove(file);
				}, 0);
			}
		},
		returnImages() {
			const list = this.fileList;
			setTimeout(() => {
				this.fileList = [];
			}, 1000);
			return list;
		},
	},
};
</script>

<style scoped>
.imageUploadWrapper {
	margin-left: 10px;
}

.imageUploadWrapper /deep/ .el-upload--picture-card {
	height: 35px;
	width: 35px;
}

.imageUploadWrapper /deep/ .el-upload-list--picture-card .el-upload-list__item {
	height: 35px;
	width: 35px;
	margin-bottom: 0px;
}
</style>
