<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "App",
	components: {},
	data() {
		return {};
	},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0px;
	overflow: hidden;
	font-family: "PT Sans", sans-serif !important;
}

* {
	box-sizing: border-box;
	font-family: "PT Sans", sans-serif !important;
}

input {
	line-height: normal !important;
}

#app {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	height: 100vh;
}

:focus-visible {
	outline: unset !important;
}

.el-card.is-always-shadow {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.cell,
.el-table__empty-text {
	color: #2c3e50 !important;
}

.pdf-app.light {
	--pdf-app-background-color: #5ab000 !important;
}

.el-dialog__header {
	display: none;
}

.el-popper {
	z-index: 10000000 !important;
}
</style>
