<template>
	<div>
		<div id="selectionBox"></div>
		<div class="wrapper">
			<el-button id="captureButton" :icon="Camera" circle />
		</div>
	</div>
</template>

<script>
import { Camera } from "@element-plus/icons-vue";
import { markRaw } from "vue";
export default {
	data() {
		return { Camera: markRaw(Camera) };
	},
	mounted() {
		const selectionBox = document.getElementById("selectionBox");
		const captureButton = document.getElementById("captureButton");
		let startX, startY, endX, endY;

		// Mouse events to draw the lasso box
		document.addEventListener("mousedown", (e) => {
			if (e.target.id === "captureButton") {
				return;
			}
			startX = e.clientX;
			startY = e.clientY;
			selectionBox.style.left = `${startX}px`;
			selectionBox.style.top = `${startY}px`;
			selectionBox.style.width = `0px`;
			selectionBox.style.height = `0px`;
			selectionBox.style.display = "block";
			document.addEventListener("mousemove", onMouseMove);
		});

		function hasParentWithId(element, id) {
			while (element) {
				if (element.id === id) {
					return true;
				}
				element = element.parentElement;
			}
			return false;
		}

		let rect = null;

		document.addEventListener("mouseup", (e) => {
			document.removeEventListener("mousemove", onMouseMove);
			const isCaptureButton = hasParentWithId(e.target, "captureButton");
			if (isCaptureButton) {
				return;
			}
			rect = selectionBox.getBoundingClientRect();
			if (rect.width < 5) {
				selectionBox.style.display = "none";
				rect = null;
			}
		});

		function onMouseMove(e) {
			endX = e.clientX;
			endY = e.clientY;
			const width = Math.abs(endX - startX);
			const height = Math.abs(endY - startY);
			selectionBox.style.width = `${width}px`;
			selectionBox.style.height = `${height}px`;
			selectionBox.style.left = `${Math.min(startX, endX)}px`;
			selectionBox.style.top = `${Math.min(startY, endY)}px`;
		}

		// Capture screenshot of the selected area
		captureButton.addEventListener("click", () => {
			let settings = {};
			if (rect) {
				settings = {
					x: rect.left,
					y: rect.top,
					width: rect.width,
					height: rect.height,
				};
			}
			this.$html2canvas(document.getElementById("vuePdfApp"), settings).then((canvas) => {
				const quality = 0.5;
				const img = canvas.toDataURL("image/png", quality);
				this.$emit("newScreenShot", { file: img });
				/* const link = document.createElement("a");
				link.href = img;
				link.download = "screenshot.png";
				link.click(); */
				selectionBox.style.display = "none"; // Hide the selection box after capture
				rect = null;
			});
		});
	},
	methods: {
		decodeBase64Image(base64Str) {
			let image = new Image();
			image.src = base64Str;
			return image;
		},
		drawImageOnCanvas(image) {
			return new Promise((resolve) => {
				image.onload = () => {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");

					// Set the desired dimensions for the canvas
					canvas.width = image.width;
					canvas.height = image.height;

					// Draw the image on the canvas
					ctx.drawImage(image, 0, 0);

					resolve(canvas);
				};
			});
		},
		compressImage(canvas, quality = 0.7) {
			return canvas.toDataURL("image/jpeg", quality);
		},
		async compressBase64Image(base64Str, quality = 0.7) {
			// Decode Base64 image
			let image = this.decodeBase64Image(base64Str);
			// Draw image on canvas
			let canvas = await this.drawImageOnCanvas(image);
			// Compress the image
			let compressedBase64 = this.compressImage(canvas, quality);
			return compressedBase64;
		},
	},
};
</script>

<style scoped>
#selectionBox {
	position: absolute;
	border: 2px dashed #000;
	display: none;
	border-radius: 2px;
}

.wrapper {
	background-color: #005a1f;
	border-radius: 50%;
	opacity: 0.95;
	position: absolute;
	width: 200px;
	height: 200px;
	z-index: 100000;
	transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1) 0s, opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1) 0s;
	transform: scale(1);
	bottom: -50px;
	right: -50px;
}

#captureButton {
	position: absolute;
	right: 90px;
	top: 60px;
	height: 50px;
	width: 50px;
	font-size: 18px;
}
</style>
