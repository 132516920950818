<template>
	<div class="common-layout">
		<div class="logo-holder">
			<img class="logo" :src="logo" />
		</div>
		<div class="content">
			<h1>Generate A Document Agent</h1>
			<p>Upload your PDF/ XLS document and receive a link to your document agent.</p>
		</div>
		<el-container style="height: 100%">
			<el-main style="margin: 0px; padding: 0px">
				<el-upload class="upload" drag action="" multiple>
					<el-icon class="el-icon--upload"><UploadFilled style="color: #007131" /></el-icon>
					<div class="el-upload__text">Drop document here or <em>click to upload</em></div>
					<template #tip>
						<div class="el-upload__tip">pdf/docx/xls files with a size less than 2mb</div>
					</template>
				</el-upload>
			</el-main>
			<img style="position: absolute; right: -100px; z-index: -1; top: 100px" :src="triangle" />
			<img style="position: absolute; left: -200px; bottom: -300px" :src="rightTriangle" />
		</el-container>
		<el-dialog :show-close="false" v-model="dialogVisible" width="500">
			<h1 style="margin-top: 0px; color: black">Document Agent Link</h1>
			<el-input v-model="link" disabled placeholder="" />
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">Cancel</el-button>
					<el-button type="primary" @click="dialogVisible = false"> Copy to clipboard </el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { UploadFilled } from "@element-plus/icons-vue";
import logo from "../assets/logo.svg";
import triangle from "../assets/triangle.svg";
import rightTriangle from "../assets/right-triangle.svg";
export default {
	components: {
		UploadFilled,
	},
	data() {
		return {
			logo: logo,
			triangle,
			rightTriangle,
			dialogVisible: true,
			link: "https://proman.tovie.ai/72d31611-15e9-47c8-9b5c-9fe13be41498-EnergyFinanceReport.pdf",
		};
	},
};
</script>

<style scoped>
.upload {
	margin: auto;
	max-width: 600px;
}

.content {
	margin-top: 20px;
	height: 200px;
	max-width: 600px;
	margin: auto;
}

.logo-holder {
	position: relative;
	width: 156px;
	height: 127px;
	align-items: center !important;
	justify-content: center !important;
}

.logo-holder:before {
	-webkit-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
	content: "";
	width: 200px;
	height: 200px;
	z-index: -1;
	background: green;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(74deg);
	transform: translate(-50%, -50%) rotate(74deg);
	margin: -17px 0 0 -24px;
}

.logo {
	width: 122px;
	margin-left: 15px;
}
</style>
